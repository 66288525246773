import { useStaticQuery, graphql } from "gatsby"

export const useMainPages = () => {
  const data = useStaticQuery(graphql`
    query MainPagesQuery {
      pages: allMarkdownRemark(
        filter: { frontmatter: { level: { eq: 1 } } }
        sort: { fields: id, order: DESC }
      ) {
        edges {
          node {
            fields {
              fixedPath
            }
            id
            frontmatter {
              bgImage
              level
              metaDesc
              metaTitle
              path
              sidebar
              title
              slug
              name
              type
            }
          }
        }
      }
    }
  `)

  return data.pages.edges
}
