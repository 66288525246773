import React from "react"
import { Box, Text } from "@chakra-ui/core"
import moment from "moment"
import { useMetaData } from "../../hooks/useMetaData"
import { Link } from "gatsby"

export const Footer = () => {
  const metaData = useMetaData()
  return (
    <Box
      bg="black"
      display="flex"
      flexDir={["column", "row"]}
      justifyContent={["center", "space-between"]}
      paddingX="2rem"
      color="white"
      fontWeight="normal"
      fontSize={["0.8rem", "0.9rem"]}
    >
      <Box flex="2">
        <Text>
          @ Copyright{moment().format("YYYY")} {metaData.siteUrl}
        </Text>
      </Box>
      <Box display="flex" flex="1" justifyContent="end">
        <Box marginX={["0.3rem", "1rem"]}>
          <Link to="/ota-yhteytta">
            <Text color="white">Ota yhteyttä</Text>
          </Link>
        </Box>
        <Box marginX={["0.3rem", "1rem"]}>
          <Link to="/tietoa-palvelusta">
            <Text color="white">Tietoa palvelusta</Text>
          </Link>
        </Box>
        <Box marginX={["0.3rem", "1rem"]}>
          <Link to="/tietoturvaseloste">
            <Text color="white">Tietoturvaseloste</Text>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
