import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PseudoBox,
  Text,
  useDisclosure,
} from "@chakra-ui/core"
import { Link } from "gatsby"
import React from "react"
import { GiHamburgerMenu } from "react-icons/gi"
import { useChildren } from "../../../hooks/useChildren"
import { useMainPages } from "../../../hooks/useMainPages"
import { LogoSvg } from "../../logo/LogoSvg"

type Props = {
  blogTemplate?: boolean
}

export const MenuBar: React.FC<Props> = ({ blogTemplate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const mainPages = useMainPages()

  const pageItems = mainPages.map((page: any) => {
    const subItems = useChildren(page.node.frontmatter.name)
    if (subItems.length === 0)
      return (
        <Link key={page.node.frontmatter.path} to={page.node.fields.fixedPath}>
          <Box
            zIndex={100000}
            as={Button}
            bg="transparent"
            color={"white"}
            border="none"
          >
            {page.node.frontmatter.slug}
          </Box>
        </Link>
      )
    return (
      <Menu>
        <MenuButton
          zIndex={100000}
          as={Button}
          bg="transparent"
          color={blogTemplate ? "action" : "black"}
          border="none"
        >
          {page.node.frontmatter.slug}
        </MenuButton>
        <MenuList bg="white" zIndex={100000000}>
          {subItems.map((item: any) => {
            return (
              <Link
                to={item.node.fields.fixedPath}
                key={item.node.frontmatter.path}
              >
                <MenuItem
                  fontWeight="400"
                  fontSize="1.1rem"
                  border="none"
                  bg="white"
                  _hover={{ background: "#6B53FD", color: "black" }}
                >
                  {item.node.frontmatter.slug}
                </MenuItem>
              </Link>
            )
          })}
        </MenuList>
      </Menu>
    )
  })

  const mobilePageItems = mainPages.map((page: any) => {
    const subItems = useChildren(page.node.frontmatter.name)
    if (subItems.length === 0)
      return (
        <Link key={page.node.frontmatter.path} to={page.node.fields.fixedPath}>
          <Box
            zIndex={100000}
            as={Button}
            bg="transparent"
            color="#10223b"
            fontWeight="800"
            border="none"
          >
            {page.node.frontmatter.slug}
          </Box>
        </Link>
      )
    return (
      <Box key={page.node.frontmatter.path}>
        <Link to={page.node.fields.fixedPath}>
          <Text
            zIndex={100000}
            as={Button}
            bg="transparent"
            color="#10223b"
            fontWeight="800"
            border="none"
          >
            {page.node.frontmatter.slug}
          </Text>
        </Link>
        <Box p="1rem">
          {subItems.map((item: any) => {
            if (item.node.frontmatter.name !== page.node.frontmatter.name)
              return (
                <Link
                  to={item.node.frontmatter.path}
                  key={item.node.frontmatter.path}
                >
                  <PseudoBox
                    fontWeight="400"
                    border="none"
                    bg="#4a2df8"
                    color="white"
                    padding="0.2rem 0.4rem"
                    borderRadius="10px"
                    margin="0.3rem"
                  >
                    {item.node.frontmatter.slug}
                  </PseudoBox>
                </Link>
              )
          })}
        </Box>
      </Box>
    )
  })

  return (
    <Box
      display="flex"
      height="50px"
      m="0rem"
      p={["1rem 0rem", "1rem 0rem"]}
      alignItems="center"
      bg="transparent"
      justifyContent="space-between"
      marginX={["1rem", "3rem", "3rem", "110px"]}
      zIndex={100}
    >
      <Box width={["160px", "160px", "220px", "250px"]}>
        <Link to="/">
          <LogoSvg />
        </Link>
      </Box>
      <Box
        justifyContent="space-between"
        display={["none", "none", "none", "none", "flex"]}
      >
        {pageItems}
      </Box>
      <Box display={["block", "block", "block", "block", "none"]}>
        <Button onClick={onOpen} bg="transparent" border="none" color="#644BFB">
          <Box as={GiHamburgerMenu} size="42px" />
        </Button>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent bg="primary" overflowY="scroll">
            <DrawerCloseButton bg="action" color="white" />
            <DrawerHeader color="white">Mitä etsit?</DrawerHeader>
            <DrawerBody>{mobilePageItems}</DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </Box>
  )
}
