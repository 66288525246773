import React from "react"
import { Box, ThemeProvider, CSSReset } from "@chakra-ui/core"
import customTheme from "../theme/theme"
import { MenuBar } from "../navigation/menuBar/MenuBar"
import { Footer } from "../footer/Footer"
import { Global } from "@emotion/core"
import CookieConsent from "react-cookie-consent"
import { ScrollUp } from "../navigation/scroll/ScrollUp"
import { NotificationBar } from "../notification/NotificationBar"

type Props = { blog?: boolean }

export const Layout: React.FC<Props> = ({ children, blog }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Global
        styles={theme => ({
          html: {
            scrollBehavior: "smooth",
          },
          body: {
            fontFamily: "Montserrat",
            margin: "0px",
            lineHeight: "2rem",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            msWordBreak: "break-word",
            wordBreak: "break-word",
            msHyphens: "auto",
            MozHyphens: "auto",
            WebkitHyphens: "auto",
            hyphens: "auto",
          },
          p: {
            margin: "1rempx",
          },
          h1: {
            margin: "0px",
            marginTop: "3rem",
            marginBottom: "0.3rem",
            padding: "0px",
          },
          h2: {
            margin: "0px",
            marginTop: "3rem",
            marginBottom: "0.3rem",
            padding: "0px",
          },
          h3: {
            margin: "0px",
            marginTop: "3rem",
            marginBottom: "0.3rem",
            padding: "0px",
          },
          h4: {
            margin: "0px",
            marginTop: "3rem",
            padding: "0px",
          },
          a: {
            textDecoration: "none",
            outline: "none",
            color: "info",
            fontWeight: 800,
            zIndex: 100,
          },
        })}
      />
      <Box>
        <Box bg="#F8F8FF">
          <MenuBar blogTemplate={blog} />
        </Box>
        <Box m="0rem" minH="86vh">
          {children}
        </Box>
        <Footer />
        <Box zIndex={100000}>
          <CookieConsent
            location="bottom"
            buttonText="Ok"
            cookieName="cookiesdassd1234566"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            Tällä sivustolla käytetään evästeitä. Jatkamalla sivuston käyttöä
            hyväksyt evästeiden käytön.
          </CookieConsent>
        </Box>
        <ScrollUp />
      </Box>
    </ThemeProvider>
  )
}
